<template>
  <div class="container-fuild">
    <div class="row justify-content-center m-0 p-lg-5 p-2 ">
      <div class="col-lg-auto col-12 login-card p-4 rounded my-5">
        <div class="text-center">
          <span class="text-center">
            <i class="bi bi-kanban-fill fs-1 icon"></i>
          </span>
          <br />
          <span class="fs-3">
            PSM - <span class="text-danger fs-3"> Gestion Commerciale </span>
          </span>
        </div>
        <br />
        <div>
          <label class="form-label" for=""> Nom d'utilisateur </label>
          <input
            v-model="auth.username"
            type="text"
            class="form-control"
            placeholder="admin123"
            :class="usernameError ? 'border text-danger border-danger' : ''"
          />
          <div class="text-danger">
            {{ usernameError }}
          </div>
        </div>
        <br />
        <div>
          <label class="form-label"> Mot de passe </label>
          <div></div>
          <input
            v-model="auth.password"
            v-on:keyup.enter="login(auth)"
            type="text"
            class="form-control"
            placeholder="@dmin2023"
            :class="passwordError ? 'border text-danger border-danger' : ''"
          />
          <div class="text-danger">
            {{ passwordError }}
          </div>
        </div>
        <br />
        <div>
          <span class="d-flex align-items-center">
            <input type="checkbox" name="" id="password_display" />
            <label for="password_display" class="ms-2 btn btn-link nav-link"
              >Afficher le mot de passe ?</label
            >
          </span>
        </div>
        <br />
        <div class="col">
          <button @click="login(auth)" class="btn btn-primary w-100 py-2 px-4">
            <span v-if="!progress"> Connecter </span>
            <span v-else-if="progress"> Vérification en cours </span>
          </button>
          <div class="text-center text-danger p-2">
            {{ error }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="row d-xl-none d-flex justify-content-center align-items-center pt-5 px-3 h-75"
    >
      <div class="col-11 bg-white rounded text-center text-danger py-5 px-3">
        <div class="text-center">
          <i
            class="bi bi-exclamation-diamond text-warning fs-1"
            :style="{ fontSize: '80px !important' }"
          ></i>
        </div>
        <hr />
        <div class="lh-4 fs-5">
          L'application ne fonctionne pas sur les téléphones car elle est conçue
          pour les ordinateurs. Veuillez vous connecter à partir de votre
          ordinateur pour accéder à l'application.
        </div>
        <hr />
        <div class="">
          <a
            href="https://api.whatsapp.com/send?phone=212600302731"
            class="nav-link d-flex align-items-center btn btn-success p-2 py-3"
          >
            <i
              class="bi bi-whatsapp text-white"
              :style="{ fontSize: '38px !important' }"
            ></i>
            <span class="text-white">
              Cliquez ici pour en savoir plus sur WhatsApp
            </span>
          </a>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      auth: {},
      progress: false,
    };
  },
  methods: {
    async login(auth) {
      await (this.progress = !this.progress);
      await this.$store.dispatch("auth/login", auth);
      setTimeout((this.progress = !this.progress), 6000);
    },
  },
  computed: {
    ...mapGetters("auth", {
      usernameError: "getUsernaneError",
      passwordError: "getPasswordError",
      error: "getError",
    }),
  },
};
</script>

<style scoped>
.container-fuild {
  min-height: 100vh;
  /* background-color: red; */
  background-color: #689ae6;
  background-image: -webkit-linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  );
  background-image: linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  );
}

.login-card {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
@keyframes icon-animation {
  0% {
    color: #689ae6;
  }
  50% {
    color: #b14343;
  }
  100% {
    color: #689ae6;
  }
}
.icon {
  animation-name: icon-animation;
}
.fs-1 {
  font-size: 55px !important;
}
.form-control {
  padding: 0.6rem 0.8rem;
}
</style>
